import React from 'react';
import { createUser, getUser } from '../db';

const acceptInvite = async (requestParams, user, auth) => {
  if (user && user.uid) return addFirestoreDoc(user.uid, requestParams.email);

  if (auth.isSignInWithEmailLink(window.location.href)) {
    return auth
      .signInWithEmailLink(requestParams.email, window.location.href)
      .then(async (response) => {
        return addFirestoreDoc(response.user.uid, requestParams.email);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  } else {
    return Promise.reject('Invalid invite link');
  }
};

const addFirestoreDoc = async (userID, email) => {
  var userDoc = await getUser(userID);

  if (!userDoc) {
    let userData = {
      admin: false,
      email: email,
      isNewUser: true,
    };
    createUser(userID, userData);
    return { isNewUser: true };
  } else {
    // return userDoc;
    return { isNewUser: false };
  }
};

export default acceptInvite;
