export function translate(langCollection) {
  const lang = localStorage.getItem('language');

  switch (lang) {
    case 'de':
      return langCollection.de;
    case 'en':
      return langCollection.en;
    default:
      return langCollection.en;
  }
}

export default translate;
