import React from 'react';
import Meta from './../components/Meta';
import HeroSection from './../components/HeroSection';
import FeaturesSection from './../components/FeaturesSection';
import ClientsSection from './../components/ClientsSection';
import TestimonialsSection from './../components/TestimonialsSection';
import NewsletterSection from './../components/NewsletterSection';
import CtaSection from './../components/CtaSection';
import translate from '../util/translator';
import { requireAuth, useAuth } from '../util/auth';
import { Col, Container, Row } from 'react-bootstrap';
import SalesComponent from '../components/SalesComponent';
import styled from 'styled-components';

const CustomRow = styled(Row)`
  border-bottom: 1px solid black;
  padding: 20px 0px;
  h1 {
    font-weight: 600;
    word-break: break-word;
    font-size: 33px;
  }

  div[class*='col'] {
    @media (min-width: 768px) {
      min-height: 80vh;
    }
  }

  .custombutton {
    background-color: black;
    padding: 10px 15px;
    border-radius: 30px;
    color: white;
    text-align: center;

    h5 {
      margin: 0px;
    }
  }

  li {
    font-size: 20px;
  }
`;

function IndexPage(props) {
  const auth = useAuth();

  return (
    <>
      <Container>
        <CustomRow>
          <Col xs={12} md={6} className='d-flex flex-column justify-content-center'>
            <h1>Montage- & Wartungshilfe</h1>
            <p>
              Wartungshilfe direkt an der Maschine mit 3D Explosionszeichnungen, Visualisierung direkt am Schreibtisch
              und schnellere Schwachstellenanalyse von Bauteilen. Techniker können Schritt-für-Schritt-Anweisungen in
              Echtzeit anzeigen, um effizientere Reparaturen durchzuführen.
            </p>
          </Col>
          <Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center'>
            <video
              src='https://yonacontent.blob.core.windows.net/website-content/media1.mp4'
              autoPlay
              loop
              muted
              style={{ maxWidth: '80%' }}
            />
            <div className='d-block'>
              <div className='custombutton my-5'>
                <h5>EFFIZIENSSTEIGERUNG </h5>
              </div>
              <div className='custombutton'>
                <h5>EXPLOSIONSZEICHNUNG</h5>
              </div>
            </div>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col xs={12} md={6} className='d-flex flex-column justify-content-center'>
            <h1>Jeder Prozess in AR</h1>
            <p>
              Nicht nur Maschinen und Geräte zur Produktion, sondern auch Büroequipment kann durch AR erklärt werden.
            </p>
            <div className='custombutton mt-20 mb-5'>
              <h5>HILFE ZUR SELBSTHILFE </h5>
            </div>
          </Col>
          <Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center'>
            <video
              src='https://yonacontent.blob.core.windows.net/website-content/medien2.mov'
              autoPlay
              loop
              muted
              style={{ maxWidth: '80%', maxHeight: '70vh', borderRadius: '50px' }}
            />
          </Col>
        </CustomRow>
        <CustomRow>
          <Col xs={12} md={6} className='d-flex flex-column justify-content-center'>
            <h1>Qualitätssicherung ohne Personalbedarf</h1>
            <p>Komplizierte Anleitungen durch interaktive Videos direkt an den Maschinen ersetzen</p>
            <div>
              <div className='custombutton mt-20'>
                <h5>SCHNELLE EINARBEITUNG </h5>
              </div>
              <div className='custombutton mt-8'>
                <h5>HÖHERE MITARBEITERMOTIVATION </h5>
              </div>
              <div className='custombutton mt-8 '>
                <h5>JUST-IN-TIME-SCHULUNGEN </h5>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center'>
            <img src='img/media3.png' style={{ maxWidth: '100%' }} />
          </Col>
        </CustomRow>
        <CustomRow>
          <Col xs={12}>
            <img src='img/media4.png' style={{ height: '100%', width: '100%' }} />
          </Col>
        </CustomRow>
        <CustomRow>
          <Col xs={12} md={6} className='d-flex flex-column justify-content-center'>
            <p style={{ fontSize: '28px' }}>
              YONA gewährleistet die Sicherheit sensibler Informationen. Die Plattform verwendet verschlüsselte
              Datenübertragung und Authentifizierung, um unbefugten Zugriff zu verhindern. - Die App erfüllt alle
              Datenschutzrichtlinien und -vorschriften, um die Vertraulichkeit der Nutzerdaten zu gewährleisten.
            </p>
          </Col>
          <Col xs={12} md={6} className='d-flex flex-column justify-content-center'>
            <h1>Volle Datensicherheit</h1>
            <div className='mt-10'>
              <ul>
                <li> ISO 27001</li>
                <li>ISO 27017</li>
                <li>ISO 27018</li>
              </ul>
              <ul>
                <li>MAC Adressen Abfrage</li>
                <li>Geschlossene Systeme</li>
              </ul>
            </div>
          </Col>
        </CustomRow>
      </Container>
    </>
  );
}

export default requireAuth(IndexPage);
