import React, { useEffect, useState } from 'react';
import { useAuth } from '../util/auth';
import { useRouter } from '../util/router';
import queryString from 'query-string';
import acceptInvite from '../util/Database/acceptInviteFunction';
import PageLoader from '../components/PageLoader';

const getRequestParams = () => {
  let email = queryString.parse(window.location.search)['user'] || '';
  if (email && email !== '') email = window.atob(email);
  return { email: email };
};

const AcceptInvitePage = () => {
  const [loader, setLoader] = useState(true);
  const auth = useAuth();
  const router = useRouter();
  const requestParams = getRequestParams();

  const handleAcceptInviteFailure = () => {
    setLoader(false);
    // router.push('/');
  };

  useEffect(() => {
    debugger;
    if (auth.user && auth.user.uid) {
      console.log(`AUTHEN`);
      acceptInvite(requestParams, auth.user, auth)
        .then((res) => {
          if (res.isNewUser) router.push('/set-password');
          else router.push('/');
        })
        .catch((err) => {
          handleAcceptInviteFailure();
          console.log(`user - handleAcceptInviteFailure: `, err);
        });
    }

    if (!auth.user) {
      acceptInvite(requestParams, null, auth)
        .then((res) => {
          if (res?.isNewUser) router.push('/set-password');
          else router.push('/');
        })
        .catch((err) => {
          handleAcceptInviteFailure();
          console.log(`no user - handleAcceptInviteFailure: `, err);
        });
    }
  }, [auth]);

  return <div> {loader && <PageLoader />} </div>;
};

export default AcceptInvitePage;
