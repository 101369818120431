import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import FormField from './FormField';
import { useAuth } from './../util/auth';
import translate from '../util/translator';
import { updateUser } from '../util/db';
import { useRouter } from '../util/router';

function SettingsPassword(props) {
  const auth = useAuth();
  const router = useRouter();
  const [pending, setPending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    auth
      .updatePassword(data.pass)
      .then(() => {
        if (auth.user.isNewUser) updateUser(auth.user.uid, { isNewUser: false });

        setTimeout(() => {
          router.push('/');
        }, 2000);

        // Clear form
        reset();
        // Set success status
        props.onStatus({
          type: 'success',
          message: translate({
            de: `Passwort wurde aktualisiert. Weiterleitung...`,
            en: `Password has been updated. Forwarding...`,
          }),
        });
      })
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          // Update state to show re-authentication modal
          props.onStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit({ pass: data.pass }),
          });
        } else {
          // Set error status
          props.onStatus({
            type: 'error',
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId='formName'>
        <FormField
          name='pass'
          type='password'
          required
          placeholder={translate({ de: 'Passwort', en: 'Password' })}
          size='lg'
          error={errors.pass}
          inputRef={register('pass', {
            required: translate({ de: 'Bitte passwort eintragen', en: 'Please enter your password' }),
            minLength: {
              value: 6,
              message: translate({
                de: 'Die Mindestlänge beträgt 6 Zeichen',
                en: 'The minimum length is 6 characters',
              }),
            },
          })}
        />
      </Form.Group>
      <Form.Group controlId='formEmail'>
        <FormField
          name='confirmPass'
          type='password'
          required
          placeholder={translate({ de: 'Passwort wiederholen', en: 'Confirm password' })}
          size='lg'
          error={errors.confirmPass}
          inputRef={register('confirmPass', {
            required: translate({ de: 'Bitte passwort erneut eintragen', en: 'Please enter your new password again' }),
            validate: (value) => {
              if (value === getValues().pass) return true;
              else return "This doesn't match your password";
            },
          })}
        />
      </Form.Group>
      <Button type='submit' size='lg' disabled={pending} className='mt-4'>
        <span> {translate({ de: 'Speichern', en: 'Save' })} </span>

        {pending && (
          <Spinner animation='border' size='sm' role='status' aria-hidden={true} className='ml-2 align-baseline'>
            <span className='sr-only'>Sending...</span>
          </Spinner>
        )}
      </Button>
    </Form>
  );
}

export default SettingsPassword;
