import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import FormField from './FormField';
import { useAuth } from './../util/auth';
import translate from '../util/translator';
import { updateUser } from '../util/db';
import { useRouter } from '../util/router';

function AuthForm(props) {
  const auth = useAuth();
  const router = useRouter();

  const [pending, setPending] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm();

  const submitHandlersByType = {
    signin: ({ email, pass }) => {
      return auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    signup: ({ pass }) => {
      setPending(true);
      auth
        .updatePassword(pass)
        .then(() => {
          if (auth.user.isNewUser) updateUser(auth.user.uid, { isNewUser: false });

          setTimeout(() => {
            router.push('/');
          }, 2000);

          props.onFormAlert({
            type: 'success',
            message: translate({
              de: 'Passwort wurde aktualisiert! Weiterleitung...',
              en: 'Password has been updated! Forwarding...',
            }),
          });
        })
        .catch((error) => {
          if (error.code === 'auth/requires-recent-login') {
            props.onFormAlert({
              type: 'requires-recent-login',
              callback: () => onSubmit({ email: auth.user.email, pass: pass }),
            });
          } else {
            props.onFormAlert({
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => setPending(false));
    },
    forgotpass: ({ email }) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: 'success',
          message: 'Password reset email sent',
        });
      });
    },
    changepass: ({ pass }) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: 'success',
          message: 'Your password has been changed',
        });
      });
    },
  };

  // Handle form submission
  const onSubmit = ({ email, pass }) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
    }).catch((error) => {
      setPending(false);
      // Show error alert message
      props.onFormAlert({
        type: 'error',
        message: error.message,
      });
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {['signin'].includes(props.type) && (
        <Form.Group controlId='formEmail'>
          <FormField
            size='lg'
            name='email'
            type='email'
            placeholder='Email'
            error={errors.email}
            inputRef={register('email', {
              required: translate({ de: 'Bitte E-Mail eintragen', en: 'Please enter an email' }),
            })}
          />
        </Form.Group>
      )}
      {['signup', 'signin', 'changepass'].includes(props.type) && (
        <Form.Group controlId='formPassword'>
          <FormField
            size='lg'
            name='pass'
            type='password'
            placeholder='Password'
            error={errors.pass}
            inputRef={register('pass', {
              required: translate({ de: 'Bitte Passwort eintragen', en: 'Please enter a password' }),
              minLength: {
                value: 6,
                message: translate({ de: 'Die Mindestlänge beträgt 6', en: 'Minimum length is 6' }),
              },
            })}
          />
        </Form.Group>
      )}
      {['signup', 'changepass'].includes(props.type) && (
        <Form.Group controlId='formConfirmPass'>
          <FormField
            size='lg'
            name='confirmPass'
            type='password'
            placeholder='Confirm Password'
            error={errors.confirmPass}
            inputRef={register('confirmPass', {
              required: translate({ de: 'Bitte Passwort erneut eintrage', en: 'Please enter password again' }),
              validate: (value) => {
                if (value === getValues().pass) {
                  return true;
                } else {
                  return translate({
                    de: 'Passwörter stimmen nicht überein',
                    en: "This doesn't match your password",
                  });
                }
              },
            })}
          />
        </Form.Group>
      )}
      <div className='text-center mt-5'>
        <Button variant='dark' block={true} size='lg' type='submit' disabled={pending}>
          {!pending && <span>{props.buttonAction}</span>}

          {pending && (
            <Spinner animation='border' size='sm' role='status' aria-hidden={true} className='align-baseline'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
          )}
        </Button>
      </div>
    </Form>
  );
}

export default AuthForm;
