import React, { useEffect, useState } from 'react';
import FormAlert from './FormAlert';
import AuthForm from './AuthForm';
import AuthSocial from './AuthSocial';
import { useRouter } from './../util/router';
import { useAuth } from '../util/auth';

function Auth(props) {
  const auth = useAuth();
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  const handleAuth = (user) => {
    router.push(props.afterAuthPath);
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      {formAlert && <FormAlert type={formAlert.type} message={formAlert.message} />}

      <AuthForm type={props.type} buttonAction={props.buttonAction} onAuth={handleAuth} onFormAlert={handleFormAlert} />
    </>
  );
}

export default Auth;
