import React from 'react';
import { useAuth } from '../util/auth';
import SetPasswordComponent from '../components/SetPasswordComponent';
import translate from '../util/translator';

const SetPasswordPage = () => {
  const auth = useAuth();
  const email = auth.user?.email;

  const titleChangePass = translate({
    de: `Bitte neues Kennwort festlegen, um die Anmeldedaten für das Konto ${email} zu ändern.`,
    en: `Set a new password to change the credentials for the ${email} account.`,
  });

  const titleRegister = translate({
    de: `YONA-Konto wird mit ${email} erstellt. Kennwort festlegen, um den Anmeldevorgang abzuschließen`,
    en: `Your YONA account is created with ${email}. Set the password of your account to complete the login process.`,
  });

  return <SetPasswordComponent title={auth.user?.isNewUser ? titleRegister : titleChangePass} />;
};

export default SetPasswordPage;
