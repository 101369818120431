import React from 'react';
import Meta from './../components/Meta';
import AuthSection from './../components/AuthSection';
import { useRouter } from './../util/router';
import { handleAuthTypes } from '../util/auth';

function AuthPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title='Auth' />
      <AuthSection
        bg='white'
        textColor='dark'
        size='md'
        bgImage=''
        bgImageOpacity={1}
        type={router.query.type}
        afterAuthPath={router.query.next || '/'}
      />
    </>
  );
}

export default AuthPage;
