import React from 'react';
import Meta from './../components/Meta';
import { useAuth } from '../util/auth';
import { useRouter } from '../util/router';

function NotFoundPage(props) {
  const auth = useAuth();
  const router = useRouter();

  if (!auth.user) router.replace('/auth/signin');
  else router.replace('/');
}

export default NotFoundPage;
