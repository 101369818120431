import React, { useEffect, useState } from 'react';
import { useAuth } from '../util/auth';
import SettingsPassword from './SettingsPassword';
import { useRouter } from '../util/router';
import { Container } from 'react-bootstrap';
import FormAlert from './FormAlert';
import SectionHeader from './SectionHeader';
import ReauthModal from './ReauthModal';

const SetPasswordComponent = (props) => {
  const auth = useAuth();
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  const [reauthState, setReauthState] = useState({ show: false });

  useEffect(() => {
    if (!auth.user) router.push('/auth/signin');
  }, [auth]);

  const handleStatus = ({ type, message, callback }) => {
    if (type === 'requires-recent-login') {
      setFormAlert(null);
      setReauthState({
        show: true,
        callback: callback,
      });
    } else {
      setFormAlert({ type: type, message: message });
    }
  };

  return (
    <Container className='mt-10'>
      <SectionHeader title={props.title} />
      {/* {reauthState.show && (
        <ReauthModal
          callback={reauthState.callback}
          provider={auth.user.providers[0]}
          onDone={() => setReauthState({ show: false })}
        />
      )} */}
      {formAlert && <FormAlert type={formAlert.type} message={formAlert.message} />}
      <SettingsPassword onStatus={handleStatus} />
    </Container>
  );
};

export default SetPasswordComponent;
