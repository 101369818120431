import React from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import translate from '../util/translator';
import Footer from './../components/Footer';
import NavbarCustom from './../components/NavbarCustom';
import './../styles/global.scss';
import './../util/analytics';
import { AuthProvider } from './../util/auth';
import { QueryClientProvider } from './../util/db';
import { Route } from './../util/router';
import NotFoundPage from './404';
import AcceptInvitePage from './acceptInvite';
import AccountPage from './account';
import AuthPage from './auth';
import DashboardPage from './dashboard';
import FirebaseActionPage from './firebase-action';
import IndexPage from './index';
import LegalPage from './legal';
import SetPasswordPage from './setPassword';
import SettingsPage from './settings';
import Meta from '../components/Meta';

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider>
        <AuthProvider>
          <NavbarCustom bg='white' variant='light' expand='md' />
          <Meta title='YONA' />
          <Routes>
            <Route path='/' element={<IndexPage />} />

            <Route path='/dashboard' element={<DashboardPage />} />

            <Route path='/auth/:type' element={<AuthPage />} />

            <Route path='/account' element={<AccountPage />} />

            <Route path='/set-password' element={<SetPasswordPage />} />

            <Route path='/invite' element={<AcceptInvitePage />} />

            <Route path='/settings/:section' element={<SettingsPage />} />

            <Route path='/legal/:section' element={<LegalPage />} />

            <Route path='/firebase-action' element={<FirebaseActionPage />} />

            <Route element={<NotFoundPage />} />
          </Routes>

          <Footer
            bg='white'
            textColor='dark'
            size='md'
            bgImage=''
            bgImageOpacity={1}
            description={translate({
              de: 'Wir machen das WOW-Erlebnis von heute zum praktischen Begleiter von Morgen',
              en: "We turn today's wow experience into tomorrow's practical companion",
            })}
            copyright={`© ${new Date().getFullYear()} Company`}
            logo='../img/yona_logo_footer_side.png'
          />
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
