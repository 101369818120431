import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from './Section';
import { Link, useRouter } from './../util/router';
import './Footer.scss';
import translate from '../util/translator';

function Footer(props) {
  const router = useRouter();
  const [lang, setLang] = useState(localStorage.getItem('language'));

  useEffect(() => {
    if (!localStorage.getItem('language')) localStorage.setItem('language', 'en');
    else localStorage.setItem('language', lang);

    document.documentElement.setAttribute('lang', localStorage.getItem('language'));
  }, [lang]);

  return (
    <Section
      id='footer'
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className='footer'>
      <Container fluid='lg'>
        <Row className='text-center'>
          <Col xl={3}>
            <Link to='/'>
              <div>
                <img className='FooterComponent__logo' src={props.logo} alt='Logo' />
              </div>
            </Link>
            <p className='FooterComponent__copyright user-select-none'>© YONA</p>
          </Col>
          <Col xl={9}>
            <Row>
              <Col xl={8}>
                {props.description && (
                  <>
                    <p className='mt-3 mt-md-0 FooterComponent__description user-select-none' xs={12} md={4}>
                      {props.description}
                    </p>
                  </>
                )}
              </Col>
              <Col xl={4} className='FooterComponent__menus'>
                <ul className='list-unstyled list-inline'>
                  <li className='list-inline-item'>
                    <a target='_blank' rel='noopener noreferrer' href='https://yona.app'>
                      <span className='icon me-1'>
                        <i className='fa-solid fa-globe' />
                      </span>
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/yona_app/'>
                      <span className='icon me-1'>
                        <i className='fab fa-instagram' />
                      </span>
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a
                      target='https://www.linkedin.com/company/yona-app/'
                      rel='noopener noreferrer'
                      href='https://linkedin.com/company/yona-app'>
                      <span className='icon me-1'>
                        <i className='fa-brands fa-linkedin-in' />
                      </span>
                    </a>
                  </li>
                </ul>
              </Col>
              <Col xl={8} className='FooterComponent__menus'>
                <ul className='list-unstyled list-inline  text-center user-select-none'>
                  <li className='list-inline-item'>
                    <a target='_blank' rel='noopener noreferrer' href='https://yona.app/team'>
                      {translate({ de: 'Kontakt', en: 'Contact' })}
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a target='_blank' rel='noopener noreferrer' href='https://yona.app/legal/imprint'>
                      {translate({ de: 'Impressum', en: 'Imprint' })}
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a target='_blank' rel='noopener noreferrer' href='https://yona.app/legal/agb'>
                      {translate({ de: 'AGB', en: 'Terms of use' })}
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a target='_blank' rel='noopener noreferrer' href='https://yona.app/legal/datenschutz'>
                      {translate({ de: 'Datenschutz', en: 'Privacy' })}
                    </a>
                  </li>
                </ul>
              </Col>
              <Col xl={4} className='FooterComponent__menus'>
                <ul className='list-unstyled list-inline'>
                  <li className='list-inline-item'>
                    <Link
                      to={router.pathname}
                      onClick={(e) => {
                        e.preventDefault();
                        setLang('de');
                        window.location.reload();
                      }}
                      className={lang === 'de' ? 'FooterComponent__activeLang' : ''}>
                      DE
                    </Link>
                  </li>
                  <li className='list-inline-item'>
                    <Link
                      to={router.pathname}
                      onClick={(e) => {
                        e.preventDefault();
                        setLang('en');
                        window.location.reload();
                      }}
                      className={lang === 'en' ? 'FooterComponent__activeLang' : ''}>
                      EN
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default Footer;
