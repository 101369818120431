import React, { useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import translate from '../../util/translator';

const YonaInputGroup = (props) => {
  return (
    <>
      {props.title ? (
        <Row className={`mb-3 ${props.className ? props.className : ''}`}>
          <Col xs={4}>
            <Form.Label style={{ overflow: 'hidden', maxWidth: '100%' }}>{props.title}</Form.Label>
          </Col>
          <Col xs={8}>
            <FormInput props={props} />
          </Col>
        </Row>
      ) : (
        <div className={`d-flex align-content-center justify-content-start ${props.className ? props.className : ''}`}>
          <FormInput props={props} />
        </div>
      )}
    </>
  );
};

const FormInput = ({ props }) => {
  const inputRef = useRef();
  const [isFirstInput, setFirstInput] = useState(true);

  const handleInput = (event) => {
    event.preventDefault();

    if (props.type === 'number') {
      if (event?.nativeEvent?.data === '.') return;
      props.handleChange({ target: { value: inputRef.current.valueAsNumber } });
    } else props.handleChange(event);
  };

  useEffect(() => {
    if (props.value) setFirstInput(false);
  }, [props.value]);

  return (
    <div className='w-100'>
      <Form.Control
        id={props.id}
        ref={inputRef}
        autoFocus={props.autofocus}
        required={props.required}
        className={`yona-input${props.default ? '-default' : ''}`}
        type={props.type || 'text'}
        name={props.name}
        value={props.value ?? ''}
        placeholder={props.placeholder}
        onChange={handleInput}
        disabled={props.disabled}
        isInvalid={props.required && !props.value && !isFirstInput}
        step={'any'}
      />
      <Form.Control.Feedback type='invalid' className='text-start'>
        {translate({ de: 'Eingabe erforderlich', en: 'Input required' })}
      </Form.Control.Feedback>
    </div>
  );
};

export default YonaInputGroup;
