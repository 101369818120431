import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from './../util/auth';
import { useRouter } from '../util/router';

function NavbarCustom(props) {
  const auth = useAuth();
  const router = useRouter();

  return (
    <Navbar bg={props.bg} variant={props.variant} expand={props.expand}>
      <Container>
        <LinkContainer to='/'>
          <Navbar.Brand>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              version='1'
              viewBox='0 0 2127 1842'
              className='yona-logo'>
              <g>
                <path
                  d='M22 18382c13-21 1427-2219 3142-4885l3120-4847h4348c2391 0 4348 2 4348 5s-1413 2200-3139 4882l-3139 4878-4352 3-4351 2 23-38zM11796 17228c-422-656-768-1196-769-1199-2-6 4318-6725 4338-6747 9-10 621 933 2951 4554 1616 2512 2941 4570 2942 4575 2 5-1877 9-4346 9h-4349l-767-1192zM5770 7863c-49-76-1207-1875-2574-3998L712 5l4346-3c3469-2 4349 1 4358 10 16 17 5123 7953 5130 7973 6 13-467 15-4340 15H5859l-89-137z'
                  transform='matrix(.1 0 0 -.1 0 1842)'></path>
              </g>
            </svg>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls='navbar-nav' className='border-0' />
        <Navbar.Collapse id='navbar-nav' className='justify-content-end'>
          <Nav>
            {auth.user && (
              <NavDropdown id='dropdown' title='Account'>
                <LinkContainer to='/account'>
                  <NavDropdown.Item active={false}>Account</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/auth/signout'>
                  <NavDropdown.Item
                    active={false}
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                      router.push('/');
                    }}>
                    Sign out
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}

            {!auth.user && (
              <Nav.Item>
                <LinkContainer to='/auth/signin'>
                  <Nav.Link active={false}>Sign in</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
