import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Section from './Section';
import SectionHeader from './SectionHeader';
import Auth from './Auth';
import AuthFooter from './AuthFooter';
import { Card } from 'react-bootstrap';
import translate from '../util/translator';

function AuthSection(props) {
  // Options by auth type
  const optionsByType = {
    // signup: {
    //   // Top Title
    //   title: 'Get yourself an account',
    //   // Button text
    //   buttonAction: 'Sign up',
    //   // Footer text and links
    //   showFooter: false,
    //   signinText: 'Already have an account?',
    //   signinAction: 'Sign in',
    //   signinPath: '/auth/signin',
    //   // Terms and privacy policy agreement
    //   showAgreement: true,
    //   termsPath: '/legal/terms-of-service',
    //   privacyPolicyPath: '/legal/privacy-policy',
    // },
    signin: {
      buttonAction: translate({ de: 'Einloggen', en: 'Sign in' }),
      showFooter: false,
      signupAction: 'Create an account',
      signupPath: '/auth/signup',
      forgotPassAction: 'Forgot Password?',
      forgotPassPath: '/auth/forgotpass',
    },
    forgotpass: {
      title: 'Get a new password',
      buttonAction: 'Reset password',
      showFooter: false,
      signinText: 'Remember it after all?',
      signinAction: 'Sign in',
      signinPath: '/auth/signin',
    },
    changepass: {
      title: 'Choose a new password',
      buttonAction: 'Change password',
    },
  };

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : 'signin';

  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <Container className='d-flex justify-content-center mt-50' style={{ minHeight: '100vh' }}>
      <Card style={{ height: '100%' }}>
        <Auth
          type={type}
          buttonAction={options.buttonAction}
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          key={type}
        />

        {options.showFooter && <AuthFooter type={type} {...options} />}
      </Card>
    </Container>
  );
}

export default AuthSection;
