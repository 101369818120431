import { deleteDoc, doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { firebaseApp } from '../firebase';
import { format } from '../db';

const db = getFirestore(firebaseApp);

//#region CREATE

export async function createUser(id, data) {
  return setDoc(doc(db, 'users', id), data, { merge: true });
}

//#endregion

//#region GET

export async function getUser(id) {
  return getDoc(doc(db, 'users', id)).then(format);
}

//#endregion

//#region DELETE

export async function deleteUserDoc(uid) {
  return deleteDoc(doc(db, 'users', uid));
}

//#endregion
