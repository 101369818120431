import React, { useState } from 'react';
import YonaInputGroup from './YonaGui/InputGroup';
import LoadingButton from './YonaGui/LoadingButton';
import translate from '../util/translator';
import { useAuth } from '../util/auth';

const SettingsInvite = () => {
  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState('dark');

  const handleInvite = async () => {
    if (!email) return;
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${window.location.origin}/invite?user=${window.btoa(email)}`,
      // This must be true.
      handleCodeInApp: true,

      // dynamicLinkDomain: 'yona-dev.web.app'
    };

    if (email) {
      auth
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          setState('success');
          setTimeout(() => {
            setState('dark');
          }, 1000);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setState('danger');
          console.log(`Error sending invite: `, error);
        });
    }
  };

  const handleClick = () => {
    setIsLoading(true);
    setState('dark');
    handleInvite();
  };

  return (
    <div className='mt-24' style={{ maxWidth: '60%' }}>
      <h5 className='mb-10'>
        {translate({ de: `Angemeldet mit E-Mail: `, en: `Logged in with e-mail: ` }) + auth.user.email}
      </h5>
      <h5>Einladung versenden</h5>
      <YonaInputGroup
        id='send-invite-input'
        required
        default
        name='email'
        type='email'
        value={email}
        handleChange={(e) => setEmail(e.target.value)}
        placeholder={translate({ de: 'E-Mail Adresse eingeben', en: 'Enter E-Mail' })}
      />
      <LoadingButton
        id='invite_user_button'
        disabled={!email}
        className='mt-2'
        loading={isLoading}
        variant={`outline-${state}`}
        callback={handleClick}>
        {translate({ de: 'Einladung versenden', en: 'Send invite' })}
      </LoadingButton>
    </div>
  );
};

export default SettingsInvite;
