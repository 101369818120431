import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const LoadingButton = ({ id, className, children, variant, disabled, callback, size, loading, type }) => {
  return (
    <>
      {!loading && (
        <Button
          id={id}
          disabled={disabled}
          className={className}
          variant={variant || 'outline-dark'}
          size={size || 'md'}
          type={type || 'button'}
          onClick={() => {
            if (callback) callback();
          }}>
          {children}
        </Button>
      )}

      {loading && (
        <Button id={`${id}_loading`} className={'mx-3 ' + className} variant={variant || 'outline-dark'} disabled>
          <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
        </Button>
      )}
    </>
  );
};

export default LoadingButton;
