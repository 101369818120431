import React from 'react';
import { Button, Container } from 'react-bootstrap';
import SettingsInvite from '../components/SettingsInvite';
import { requireAuth, useAuth } from '../util/auth';
import translate from '../util/translator';

const AccountPage = () => {
  const auth = useAuth();

  const handleDeleteUser = () => {
    auth.deleteUser();
  };
  return (
    <Container>
      {auth.user.email !== 'user@yona.app' && (
        <Button variant='danger' onClick={handleDeleteUser}>
          {translate({ de: 'Account löschen', en: 'Delete account' })}
        </Button>
      )}

      {auth.user.admin && <SettingsInvite />}
    </Container>
  );
};

export default requireAuth(AccountPage);
